import * as bootstrap from 'bootstrap';
import * as prismjs from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-plsql';
import 'prismjs/components/prism-nginx';
import 'prismjs/components/prism-haml';
import 'prismjs/components/prism-erb';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import 'prismjs/plugins/toolbar/prism-toolbar';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import './admin/admin';
Prism.plugins.NormalizeWhitespace.setDefaults({
	'remove-indent': true,
	'left-trim': true,
	'right-trim': true,
	'indent': 0,
	'remove-initial-line-feed': true,
	'tabs-to-spaces': 2
})
