export class Editor {
  get #editorArea() {
    return document.querySelector('.editor-area')
  }

  get selectionStart() {
    return this.#editorArea.selectionStart
  }

  set selectionStart(pos) {
    this.#editorArea.selectionStart = pos
  }

  get selectionEnd() {
    return this.#editorArea.selectionEnd
  }

  set selectionEnd(pos) {
    this.#editorArea.selectionEnd = pos
  }

  get selection() {
    return this.value.substring(this.selectionStart, this.selectionEnd)
  }

  get value() {
    return this.#editorArea.value
  }

  set value(val) {
    this.#editorArea.value = val
  }

  focus() {
    this.#editorArea.focus()
  }

  #insertAtCaret(value) {
    let startPos = this.selectionStart
    this.value = this.value.substring(0,startPos) + value + this.value.slice(this.selectionEnd)
  }

  insertTag(openTag, closeTag) {
    this.focus()

    let selStart = this.selectionStart;
    let selection = this.selection;
    let content = `${openTag}${this.selection}${closeTag}`

    this.#insertAtCaret(content)

    this.selectionEnd = this.selectionStart

  }

  insertParagraph() { return this.insertTag('<p>', '</p>\n'); }
  insertBold() { return this.insertTag('<b>', '</b>') }
  insertItalic() { return this.insertTag('<i>', '</i>') }
  insertH2() { return this.insertTag('<h2>', '</h2>\n') }
  insertH3() { return this.insertTag('<h3>', '</h3>\n') }
  insertCode() { return this.insertTag('<code>', '</code>') }
  insertListItem() { return this.insertTag('<li>', '</li>\n') }

  insertList() {
    this.insertTag('<ul>', '</ul>')
    insertListItem()
  }

  insertOrderedList() {
    this.insertTag('<ol>', '</ol>')
    insertListItem()
  }

  insertLink() {
    let selStart = this.selectionStart;
    this.insertTag('<a href="" rel="nofollow" target="_blank">', '</a>')
    this.selectionStart = selStart + 9
    this.selectionEnd = this.selectionStart
    return false;
  }

  escapeHTML(text) {
    return text.replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\//g, '&#x2F;')
  }

  escapeText() {
    console.log(this.selection)
    let escaped = this.escapeHTML(this.selection)
    this.#insertAtCaret(escaped)
  }

  insertCodeBlock() {
    let pos = this.selectionStart
    this.insertTag('<h6></h6>\n<pre class="language-ruby line-numbers">\n  <code>\n', '\n</code>\n</pre>\n')

    this.selectionStart = pos + 4
    this.selectionEnd = this.selectionStart
  }

  constructor() {
    if(!this.#editorArea)
      return

    document.querySelector('.escape-text').addEventListener('click', (e) => { this.escapeText() })
    document.querySelector('.insert-p').addEventListener('click', (e) => { this.insertParagraph() })
    document.querySelector('.insert-bold').addEventListener('click', (e) => { this.insertBold() })
    document.querySelector('.insert-italic').addEventListener('click', (e) => { this.insertItalic() })
    document.querySelector('.insert-link').addEventListener('click', (e) => { this.insertLink() })
    document.querySelector('.insert-code').addEventListener('click', (e) => { this.insertCode() })
    document.querySelector('.insert-code-block').addEventListener('click', (e) => { this.insertCodeBlock() })
    document.querySelector('.insert-h2').addEventListener('click', (e) => { this.insertH2() })
    document.querySelector('.insert-h3').addEventListener('click', (e) => { this.insertH3() })
    document.querySelector('.insert-list').addEventListener('click', (e) => { this.insertList() })
    document.querySelector('.insert-ordered-list').addEventListener('click', (e) => { this.insertOrderedList() })
    document.querySelector('.insert-list-item').addEventListener('click', (e) => { this.insertListItem() })
  }
}
