import { Editor } from './editor'

window.addEventListener('DOMContentLoaded', (event) => {
  const iconClassField = document.querySelector('#article_icon')
  const iconPreview = document.querySelector('.admin-icon-preview')
  const e = new Editor();

  if(!iconClassField || !iconPreview)
    return;

  function updateIconPreview() {
    iconPreview.className = iconClassField.value;
  }

  iconClassField.addEventListener('keyup', (e) => {
    updateIconPreview()
  })

  updateIconPreview();
})
